import React from "react";
import { graphql } from "gatsby";

import { VIOLATION_ICONS } from "../../content/violation-icons";
import VIOLATION_TYPE from "../../enums/violation-type.json";

import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";
import ButtonLink from "../components/button-link";

import styles from "./violations.module.scss";

export const query = graphql`
  query ViolationsQuery($locale: String!) {
    translations(locale: $locale) {
      enums {
        violationType
      }
      violations
      violationDescriptions
      ...LayoutTranslations
    }
  }
`;

const ViolationsPage = () => {
  const enums = useTranslations("enums");
  const t = useTranslations("violations");
  const violationDescriptions = useTranslations("violationDescriptions");

  return (
    <>
      <SEO title={t.heading} description={t.description} />

      {Object.keys(VIOLATION_TYPE).map(key => (
        <section key={key}>
          <div className="bp-container">
            <h1 id={key} className={styles.heading}>
              {enums.violationType[key]}
            </h1>
            <img src={VIOLATION_ICONS[key]} className={styles.image} alt="" />

            <div className={`bp-cards bp-2 ${styles.cards}`}>
              {violationDescriptions[key].map(({ title, subtitle, points }) => (
                <div key={title} className={`bp-card ${styles.card}`}>
                  <h2>{title}</h2>
                  {subtitle && <p>{subtitle}</p>}
                  <ul className="bp-styled-list">
                    {points.map((point, pointIndex) => (
                      <li key={point}>
                        {point}
                        {pointIndex < points.length - 1 ? ";" : "."}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            <div className={styles.registerButtonContainer}>
              <ButtonLink className="bp-standard" to={`/report-violation?violation=${key}`}>
                {t.register}
              </ButtonLink>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default ViolationsPage;
